import React from 'react'
import Entries from './components/Entries'
import Summary from './components/Summary'
import LoginControl from './components/LoginControl'
import FilterControl from './components/FilterControl'

import { Container, Row, Col, Navbar, Nav } from 'react-bootstrap'
import EntryForm from './components/EntryForm';
import Balance from './components/Balance';
import './css/App.css'

export default class App extends React.Component {

  constructor(props) {
    super(props)
    let state = localStorage["appState"]
    if (state) {
      this.state = JSON.parse(state)
    } else {
      this.state = {
        user: null,
      }
    }

    this.state.month = ((new Date()).getMonth() + 1)
    this.state.year = (new Date()).getFullYear()

    this.onMonthChange = this.onMonthChange.bind(this)
    this.onYearChange = this.onYearChange.bind(this)
    this.onLoginChange = this.onLoginChange.bind(this)
    this.onLogoutChange = this.onLogoutChange.bind(this)
    this.onEntryChange = this.onEntryChange.bind(this)
  }

  onMonthChange(month) {
    this.setState({ month })
  }

  onYearChange(year) {
    this.setState({ year })
  }

  onLoginChange(user) {
    this.setState({ user })
    localStorage['appState'] = JSON.stringify(this.state)
  }

  onLogoutChange() {
    localStorage.removeItem('appState')
    this.setState({ user: null })
  }

  onEntryChange(entry) {
    this.setState({ entry })
  }

  render() {
    return (
      <Container fluid="true">
        <Row noGutters="false" className="justify-content-md-center">
          <Col lg={11} md={8}>
            <Navbar collapseOnSelect expand="lg" className="bg-lightn">
              <Navbar.Brand href="/">
                <img src={process.env.PUBLIC_URL + '/logo.png'} alt="logo" />
                Bills v5
              </Navbar.Brand>
              <Nav className="mr-auto">
                <FilterControl
                  month={this.state.month}
                  year={this.state.year}
                  onMonthChange={this.onMonthChange}
                  onYearChange={this.onYearChange}
                />
              </Nav>

              <Nav>
                <Balance
                  month={this.state.month}
                  year={this.state.year}
                  user={this.state.user}
                />
              </Nav>

              <Nav>
                <LoginControl
                  user={this.state.user}
                  onLoginChange={this.onLoginChange}
                  onLogoutChange={this.onLogoutChange}
                />
              </Nav>
            </Navbar>
          </Col>
        </Row>
        <Row noGutters="false" className="justify-content-md-center padme">
          <Col lg={11} md={8}>
            <EntryForm
              month={this.state.month}
              year={this.state.year}
              user={this.state.user}
              onEntryChange={this.onEntryChange}
            />
            <Entries
              month={this.state.month}
              year={this.state.year}
              user={this.state.user}
              entry={this.state.entry}
            />
          </Col>
        </Row>
        <Row noGutters="false" className="justify-content-md-center padme">
          <Col lg={11} md={8}>
            <Summary
              month={this.state.month}
              year={this.state.year}
              user={this.state.user}
            />
          </Col>
        </Row>
      </Container>
    )
  }
}