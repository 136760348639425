import axios from 'axios'
import React from 'react'
import { Table, Row, Col, Badge } from 'react-bootstrap'
import Card from 'react-bootstrap/Card';
import 'moment-timezone'
import { List } from 'react-content-loader'

export default class Summary extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            summary: {}
        }
    }

    componentDidMount() {
        this._populateSummary(this.props.month, this.props.year, this.props.user)
    }

    componentWillReceiveProps(nextProps) {
        this.setState({ summary: {} })
        this._populateSummary(nextProps.month, nextProps.year, nextProps.user)
    }

    render() {
        if (this.props.user == null) {
            return (
                <div></div>
            )
        }
        if (this.state.current && Object.keys(this.state.current).length > 0) {
            return (
                <Row className="justify-content-md-center padme">
                    <Col lg={6} md={4}>
                        <Card>
                            <Card.Body>
                                <Card.Title>Current month</Card.Title>
                                <Table responsive striped bordered hover text="white">
                                    <thead>
                                        <tr>
                                            <th>Category</th>
                                            <th className="bg-success">Credits</th>
                                            <th className="bg-warning">Debits</th>
                                            <th className="bg-secondary">Debits % Change</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this._getCurrentSummaryRows()}
                                    </tbody>
                                </Table>
                            </Card.Body>
                        </Card>

                    </Col>
                    <Col lg={6} md={4}>
                        <Card>
                            <Card.Body>
                                <Card.Title>Previous month</Card.Title>
                                <Table responsive striped bordered hover text="white">
                                    <thead>
                                        <tr>
                                            <th>Category</th>
                                            <th className="bg-success">Credits</th>
                                            <th className="bg-warning">Debits</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this._getPreviousSummaryRows()}
                                    </tbody>
                                </Table>
                            </Card.Body>
                        </Card>

                    </Col>
                </Row>
            )
        } else if (this.props.user) {
            return (
                <List />
            )
        }
    }

    _populateSummary(month, year, user) {
        if (user == null) {
            return
        }
        axios.defaults.headers.common['Authorization'] = `Bearer ${user.token}`
        axios.get(process.env.REACT_APP_API_URL + '/api/summary', {
            params: { month, year }
        }).then((response) => {
            return response.data
        }).then((summary) => {
            const current = summary.current
            const previous = summary.previous
            this.setState({
                current, previous, summary
            })
            return { current, previous, summary }
        }).catch((error) => {
            console.error(error);
        })
    }

    _getCurrentSummaryRows() {
        const categories = this.state.current.categories
        const credits = this.state.current.credits
        const debits = this.state.current.debits
        const debitsPctChg = this.state.current.debitsPctChg

        return categories.map((category) => {
            return (
                <tr key={category}>
                    <td>{category}</td>
                    <td>
                        {new Intl.NumberFormat('en-US', {
                            style: 'currency',
                            currency: 'USD'
                        }).format(credits[category] / 100)}
                    </td>
                    <td>
                        {new Intl.NumberFormat('en-US', {
                            style: 'currency',
                            currency: 'USD'
                        }).format(debits[category] / 100)}
                    </td>
                    <td>
                        {debitsPctChg[category] < 0 ? (
                            <h5>
                                <Badge variant="danger">
                                    {new Intl.NumberFormat('en-US', {
                                        style: 'percent',
                                    }).format(debitsPctChg[category] / 100)}
                                </Badge>
                            </h5>
                        ) : (
                            <h5>
                                <Badge variant="warning">
                                    {new Intl.NumberFormat('en-US', {
                                        style: 'percent',
                                    }).format(debitsPctChg[category] / 100)}
                                </Badge>
                            </h5>
                        )}

                    </td>
                </tr>
            )
        })
    }

    _getPreviousSummaryRows() {
        const categories = this.state.previous.categories
        const credits = this.state.previous.credits
        const debits = this.state.previous.debits

        return categories.map((category) => {
            return (
                <tr key={category}>
                    <td>
                        {category}
                    </td>
                    <td>
                        {new Intl.NumberFormat('en-US', {
                            style: 'currency',
                            currency: 'USD'
                        }).format(credits[category] / 100)}
                    </td>
                    <td>
                        {new Intl.NumberFormat('en-US', {
                            style: 'currency',
                            currency: 'USD'
                        }).format(debits[category] / 100)}
                    </td>

                </tr>
            )
        })
    }

}