import axios from 'axios'
import React from 'react'
import { Badge } from 'react-bootstrap'
import { Code } from 'react-content-loader';

export default class Balance extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            balance: 0,
        }
    }

    componentDidMount() {
        this._populateBalance(this.props.month, this.props.year, this.props.user)
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            balance: 0,
        })
        this._populateBalance(nextProps.month, nextProps.year, nextProps.user)
    }

    render() {
        let balance = 0
        if (this.state.balance) {
            balance = parseInt(this.state.balance) / 100
        }
        if (balance === 0) {
            return (
                <Code />
            )
        }
        if (this.props.user == null) {
            return (
                <Code />
            )
        }
        const balanceClass = balance > 0 ? 'primary' : 'secondary'
        return (
            <div className="money">
                <Badge variant={balanceClass}>
                    {new Intl.NumberFormat('en-US', {

                        style: 'currency',
                        currency: 'USD'
                    }).format(balance)}
                </Badge>
                &nbsp;&nbsp;&nbsp;
            </div>
        )
    }

    _populateBalance(month, year, user) {
        if (user == null) {
            return
        }
        axios.defaults.headers.common['Authorization'] = `Bearer ${user.token}`
        axios.get(process.env.REACT_APP_API_URL + '/api/balance', {
            params: { month, year }
        }).then((response) => {
            return response.data
        }).then((balances) => {
            return balances.data
        }).then((balances) => {
            this.setState({
                balance: balances.bankBalance,
            })
        }).catch((error) => {
            console.error({ error })
            throw error
        })
    }
}