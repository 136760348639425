import axios from 'axios'
import React from 'react'
import Autosuggest from 'react-autosuggest'
import '../css/AutoSuggestAssist.css'

export default class AutoSuggestField extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      value: '',
      suggestions: []
    }

    this.onChange = this.onChange.bind(this)
    this.onSuggestionSelected = this.onSuggestionSelected.bind(this)
    this.getSuggestionValue = this.getSuggestionValue.bind(this)
    this.renderSuggestion = this.renderSuggestion.bind(this)
  }

  getSuggestionValue(suggestion) {
    return suggestion[this.props.field]
  }

  renderSuggestion(suggestion) {
    return (
      <span>{this.getSuggestionValue(suggestion)}</span>
    )
  }

  onChange = (event, { newValue, method }) => {
    this.setState({
      value: newValue,
    })
    this.props.onAutoSuggestChange(this.props.id, newValue)
  }

  onSuggestionsFetchRequested = ({ value }) => {
    if (this.props.user == null) {
      return
    }
    const table = encodeURIComponent(this.props.table)
    const field = encodeURIComponent(this.props.field)
    const query = encodeURIComponent(value)

    axios.defaults.headers.common['Authorization'] = `Bearer ${this.props.user.token}`
    axios
      .get(`${process.env.REACT_APP_API_URL}/api/search?table=${table}&field=${field}&q=${query}`)
      .then((response) => {
        return response.data
      }).then((suggestions) => {
        return suggestions.data
      }).then((suggestions) => {
        this.setState({ suggestions })
      }).catch((error) => {
        console.error(error);
      })
  }

  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: []
    })
  }

  onSuggestionSelected(_, { suggestion }) {
    this.props.onAutoSuggestChange(this.props.id, this.getSuggestionValue(suggestion))
  }

  render() {
    const { value, suggestions } = this.state;
    const inputProps = {
      placeholder: `Search ${this.props.table}`,
      value,
      onChange: this.onChange
    }
    return (
      <Autosuggest
        suggestions={suggestions}
        onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
        onSuggestionsClearRequested={this.onSuggestionsClearRequested}
        onSuggestionSelected={this.onSuggestionSelected}
        getSuggestionValue={this.getSuggestionValue}
        renderSuggestion={this.renderSuggestion}
        inputProps={inputProps}
        id={this.props.id} />
    )
  }
}
