import React from 'react'
import { Button, Form } from 'react-bootstrap'
import axios from 'axios'
import { User } from '../entities/User'

export default class LoginControl extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            username: null,
            password: null
        }

        this.handleChange = this.handleChange.bind(this)
        this.handleLogin = this.handleLogin.bind(this)
        this.handleLogout = this.handleLogout.bind(this)
        this.onLoginChange = this.onLoginChange.bind(this)
        this.onLogoutChange = this.onLogoutChange.bind(this)
    }

    handleChange(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    handleLogin(event) {
        event.preventDefault();
        if (this.props.loggedIn !== true) {
            this._loginUser(this.state.username, this.state.password)
        } else {
            console.info('User already logged in')
        }
    }

    handleLogout(event) {
        event.preventDefault();
        this._logoutUser()
    }

    onLoginChange(user) {
        this.props.onLoginChange(user)
    }

    onLogoutChange() {
        this.props.onLogoutChange()
    }

    render() {
        if (this.props.user && this.props.user.loggedIn) {
            return (
                <Form inline method="post" onSubmit={this.handleLogout}>
                    <Button
                        variant="danger"
                        type="submit"
                        onClick={this.props.onClick}
                        id="login-form-button">
                        Logout
                    </Button>
                </Form>
            )
        }
        return (
            <Form inline method="post" onSubmit={this.handleLogin}>
                <Form.Control
                    type="text"
                    placeholder="Enter username"
                    onChange={this.handleChange}
                    name="username" />
                <Form.Control
                    type="password"
                    placeholder="Password"
                    onChange={this.handleChange}
                    name="password" />
                <Button
                    variant="info"
                    type="submit"
                    onClick={this.props.onClick}
                    id="login-form-button">
                    Submit
                </Button>
            </Form>
        );
    }

    _loginUser = (username, password) => {
        const data = { username, password }
        axios.post(process.env.REACT_APP_API_URL + "/login", data, {
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(json => {
            if (json) {
                console.info('Successfully logged in')
                const user = new User()
                user.token = json.data.token
                this.props.onLoginChange(user.toObject())

            } else {
                console.error('Login failed')
                this._logoutUser()
            }
        }).catch(e => {
            console.error(e)
            this._logoutUser()
        });
    }

    _logoutUser = () => {
        this.props.onLogoutChange()
    }

}