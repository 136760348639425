import React from 'react'
import { Form } from 'react-bootstrap'
const moment = require('moment');

export default class FilterControl extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            monthOptions: null,
            yearOptions: null,
        };
        moment.locale('en');
        this.handleMonthChange = this.handleMonthChange.bind(this);
        this.handleYearChange = this.handleYearChange.bind(this);
    }

    componentDidMount() {
        this.setMonthOptions()
        this.setYearOptions()
    }

    handleMonthChange = (event) => {
        this.props.onMonthChange(event.target.value)
    }

    handleYearChange = (event) => {
        this.props.onYearChange(event.target.value)
    }

    setMonthOptions() {
        let monthOptions = moment.monthsShort().map(
            (month, i) => {
                return (
                    <option key={i} value={i + 1}>{month}</option>
                )
            })
        this.setState({ monthOptions })
    }

    setYearOptions() {
        const currentYear = (new Date()).getFullYear()
        const start = currentYear - 10
        const end = currentYear + 10
        const years = Array(end - start + 1).fill(start).map((x, y) => x + y)
        let yearOptions = years.map(
            (year, i) => {
                return (
                    <option key={i} value={year}>{year}</option>
                )
            })
        this.setState({ yearOptions })
    }

    render() {
        return (
            <Form id="filterForm" inline>
                <Form.Group>
                    <Form.Control
                        as="select"
                        name="monthOptions"
                        onChange={this.handleMonthChange}
                        id="month" value={this.props.month}
                        size="sm">
                        {this.state.monthOptions}
                    </Form.Control>
                    &nbsp;&nbsp;
                    <Form.Control
                        as="select"
                        name="yearOptions"
                        onChange={this.handleYearChange}
                        id="year"
                        value={this.props.year}
                        size="sm">
                        {this.state.yearOptions}
                    </Form.Control>
                </Form.Group>
            </Form>
        )
    }
}