export class User {
    token = null
    timestamp = null

    constructor() {
        this.timestamp = (new Date()).toString()
    }

    loggedIn() {
        return this.token !== null
    }

    toObject() {
        return {
            loggedIn: this.loggedIn(),
            token: this.token,
            timestamp: this.timestamp
        }
    }
}