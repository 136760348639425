import axios from 'axios'
import React from 'react'
import { Button, Table, Badge } from 'react-bootstrap'
import Moment from 'react-moment'
import 'moment-timezone'
import { List } from 'react-content-loader'

export default class Entries extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      entries: []
    }
    this.handleDeleteClicked = this.handleDeleteClicked.bind(this)
  }

  componentDidMount() {
    this._populateEntries(this.props.month, this.props.year, this.props.user)
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ entries: [] })
    this._populateEntries(nextProps.month, nextProps.year, nextProps.user)
  }

  render() {
    if (this.props.user == null) {
      return (
        <div></div>
      )
    }
    if (this.state.entries && this.state.entries.length > 0) {
      return (
        <div>
          <Table responsive striped bordered hover>
            <thead>
              <tr>
                <th>Date</th>
                <th>Name</th>
                <th>Amount</th>
                <th>Category</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {this._getEntryCards()}
            </tbody>
          </Table>
        </div>
      )
    } else {
      return (
        <List />
      )
    }
  }

  handleDeleteClicked(event) {
    const self = this
    const entryId = event.currentTarget.value
    if (this.props.user == null) {
      return
    }
    axios.defaults.headers.common['Authorization'] = `Bearer ${this.props.user.token}`

    axios.delete(`${process.env.REACT_APP_API_URL}/api/entries/${entryId}`)
      .then((response) => {
        if (response?.data?.didDelete) {
          if (this.state.entries) {
            const updatedEntries = this.state.entries.filter((entry) => {
              return entry.id !== entryId
            })
            self.setState({
              entries: updatedEntries
            })
          } else {
            console.error('Cannot find entries')
          }
        } else {
          console.error('Cannot delete entry')
        }
        return response
      }).catch((error) => {
        console.error(error);
      })
  }

  _populateEntries(month, year, user) {
    if (user == null) {
      return
    }
    axios.defaults.headers.common['Authorization'] = `Bearer ${user.token}`
    axios.get(process.env.REACT_APP_API_URL + '/api/entries', {
      params: { month, year }
    }).then((response) => {
      return response.data
    }).then((entries) => {
      return entries.data
    }).then((entries) => {
      const credits = this._sumCreditOrDebits('Credit', entries)
      const debits = this._sumCreditOrDebits('Debit', entries)
      this.setState({
        credits: credits,
        debits: debits,
        entries
      })
      return { credits, debits, entries }
    }).catch((error) => {
      console.error(error);
    })
  }

  // qualifier = [Credit, Debit]
  _sumCreditOrDebits(qualifier, entries) {
    if (!entries) {
      return 0
    }
    return entries.map((entry, _) => {
      if (entry.type === qualifier) {
        return parseInt(entry.amount)
      } else {
        return 0
      }
    }).reduce((a, b) => a + b, 0)
  }

  _getEntryCards() {
    return this.state.entries.map((entry, key) => {
      let variant = entry.type === 'Credit' ? 'success' : 'warning'
      return (
        <tr key={key}>
          <td>
            <Moment
              date={entry.createdAt}
              format="MMM DD"
            />
          </td>
          <td>{entry.name.value}</td>
          <td>
            <h5>
              <Badge variant={variant}>
                {new Intl.NumberFormat('en-US', {
                  style: 'currency',
                  currency: 'USD'
                }).format(entry.amount / 100)}
              </Badge>
            </h5>
          </td>
          <td>{entry.category.value}</td>
          <td>
            <Button variant={variant}
              onClick={this.handleDeleteClicked}
              value={entry.id}>
              Delete
            </Button>
          </td>
        </tr>
      )
    })
  }

}