import axios from 'axios'
import React from 'react'
import { Button, Form, Col } from 'react-bootstrap'
import AutoSuggestField from './AutoSuggestField'
import 'moment-timezone'

export default class EntryForm extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            type: 'Debit',
        }
        this.handleChange = this.handleChange.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.handleRadio = this.handleRadio.bind(this)
        this.handleAutoSuggestChange = this.handleAutoSuggestChange.bind(this)
    }

    handleSubmit(event) {
        event.preventDefault()
        event.stopPropagation()

        let state = this.state
        state['month'] = parseInt(this.props.month)
        state['year'] = parseInt(this.props.year)

        axios.post(process.env.REACT_APP_API_URL + '/api/entries', state)
            .then((entry) => {
                this.props.onEntryChange(entry)
            })
            .catch((error) => {
                console.log(error);
            });
    }

    handleChange(event) {
        this.setState({
            [event.target.name]: event.target.value
        })
    }

    handleRadio(event) {
        this.setState({
            type: event.target.value
        });
    }

    handleAutoSuggestChange(id, value) {
        this.setState({
            [id]: value
        })
    }

    render() {
        if (!this.props.user || !this.props.user.loggedIn) {
            return (
                <div></div>
            )
        }
        return (
            <Form onSubmit={this.handleSave} lg="12">
                <Form.Row>
                    <Form.Group controlId="type" as={Col} lg="2" md="2">
                        <Form.Label>Type</Form.Label>
                        <br />
                        <Form.Check
                            inline
                            defaultChecked
                            label="Debit"
                            value="Debit"
                            type="radio"
                            name="type-radios"
                            onChange={this.handleRadio}
                        />
                        <Form.Check
                            inline
                            label="Credit"
                            value="Credit"
                            type="radio"
                            name="type-radios"
                            onChange={this.handleRadio}
                        />
                    </Form.Group>
                    <Form.Group controlId="nameid" as={Col} md="4">
                        <Form.Label>Name</Form.Label>
                        <AutoSuggestField
                            user={this.props.user}
                            table="names"
                            field="value"
                            id="name"
                            onAutoSuggestChange={this.handleAutoSuggestChange}
                        />
                    </Form.Group>
                    <Form.Group controlId="catid" as={Col} md="2">
                        <Form.Label>Category</Form.Label>
                        <AutoSuggestField
                            user={this.props.user}
                            table="categories"
                            field="value"
                            id="category"
                            onAutoSuggestChange={this.handleAutoSuggestChange}
                        />
                    </Form.Group>
                    <Form.Group as={Col} lg="1" md="2" controlId="amount">
                        <Form.Label>Amount</Form.Label>
                        <Form.Control type="number" name="amount" required onChange={this.handleChange} />
                    </Form.Group>
                    <Form.Group as={Col} md="1">
                        <Form.Label>&nbsp;Action</Form.Label>
                        <br />
                        <Button
                            type="submit"
                            onClick={this.handleSubmit}
                            id="add-form-button"
                            variant="primary"
                            size="xxl">
                            Add
                        </Button>
                    </Form.Group>
                </Form.Row>
            </Form>
        )
    }
}